import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import getInTouch from "../views/getInTouchView.vue";
import articles from "../views/articlesView.vue";
import articlesDetail from "../views/articlesDetailView.vue";
import leadershipDetail from "../views/leadershipDetailView.vue";
import productservices from "../views/productServices.vue";
import ourSuccess from "../views/ourSuccess.vue";
import about from "../views/aboutUs.vue";
import visionMission from "../views/visionMission.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "Home",
    },
  },
  {
    path: "/contact",
    name: "getInTouch",
    component: getInTouch,
    meta: {
      title: "Contact",
    },
  },
  {
    path: "/article",
    name: "articles",
    component: articles,
    meta: {
      title: "Articles",
    },
  },
  {
    path: "/article/:id",
    name: "articlesDetail",
    component: articlesDetail,
    meta: {
      title: "Article",
    },
  },
  {
    path: "/products",
    name: "productservices",
    component: productservices,
    meta: {
      title: "Product & Services",
    },
  },
  {
    path: "/oursuccess",
    name: "ourSuccess",
    component: ourSuccess,
    meta: {
      title: "Our Success",
    },
  },
  {
    path: "/about",
    name: "about",
    component: about,
    meta: {
      title: "About",
    },
  },
  {
    path: "/visionmission",
    name: "visionMission",
    component: visionMission,
    meta: {
      title: "Vision & Mission",
    },
  },
  {
    path: "/leadership/:id",
    name: "leadershipDetail",
    component: leadershipDetail,
    meta: {
      title: "Leadership",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.title == "Home") {
    document.title = `Brainworx System`;
  } else document.title = `Brainworx System - ${to.meta.title}`;
  next();
});

export default router;
