<template>
  <nav
    :class="{ scrolled: !view.atTopOfPage }"
    class="grid grid-cols-2 p-4 md:p-8 lg:p-16 md:pt-4 lg:pt-8 bg-transparent items-center w-full text-white z-10"
  >
    <a
      id="back-link"
      class="font-fontbold text-xxs md:text-base lg:text-xl flex gap-4 items-center hover:border-b-2 w-fit border-white"
    >
      <Icon icon="akar-icons:arrow-left" />Back to {{ back }}
    </a>
  </nav>
</template>
<style lang="scss">
nav.scrolled {
  background-color: #1e1e1e;
  transition: background-color 1s ease-in-out, padding-top 0.5s ease-in-out;
  padding-top: 16px;
  padding-bottom: 16px;
  //   transition: padding-top 0.5s ease-in-out;
}
</style>

<script>
import { Icon } from "@iconify/vue";
export default {
  components: {
    Icon,
  },
  name: "NavBar",
  data() {
    return {
      view: {
        atTopOfPage: true,
      },
      back: "",
    };
  },
  mounted() {
    var element = document.getElementById("back-link");
    element.setAttribute("href", document.referrer);
    this.back = document.referrer.toString().split("/").pop();
    if (this.back == "") {
      this.back = "home";
    }
    element.onclick = function () {
      window.history.back();
      return false;
    };
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    top() {
      if (this.$router.currentRoute.value.path === "/") {
        window.scrollTo(0, 0);
      } else {
        this.$router.push("/");
      }
    },
    handleScroll() {
      // when the user scrolls, check the pageYOffset
      if (window.pageYOffset > 0) {
        // user is scrolled
        if (this.view.atTopOfPage) this.view.atTopOfPage = false;
      } else {
        // user is at top of page
        if (!this.view.atTopOfPage) this.view.atTopOfPage = true;
      }
    },
  },
};
</script>
