<template>
  <footer id="footer">
    <div
      class="grid grid-cols-none grid-rows-2 md:grid-rows-none md:grid-cols-2"
    >
      <div class="md:hidden p-4 grid grid-cols-2 gap-6 bg-bgfooter">
        <div class="flex flex-col justify-between">
          <img src="../assets/bwx-logo.png" alt="" class="w-full mx-auto" />
          <div class="text-xs mx-auto">
            <strong>PT. Brainworx Solusi Integrasi</strong>
          </div>
          <div class="flex justify-between text-2xl px-2">
            <a
              href="https://www.facebook.com/brainworxindonesia/"
              target="_blank"
            >
              <Icon icon="akar-icons:facebook-fill" />
            </a>
            <a
              href="https://www.linkedin.com/company/brainworx-solusi-integrasi"
              target="_blank"
            >
              <Icon icon="akar-icons:linkedin-fill" />
            </a>
            <a
              href="https://www.instagram.com/brainworxindonesia/?hl=id"
              target="_blank"
            >
              <Icon icon="akar-icons:instagram-fill" />
            </a>
          </div>
        </div>
        <div class="flex flex-col justify-between text-xs">
          <div><strong>Email:</strong> info_bsi@brainworx.co.id</div>
          <div>
            <strong>Address:</strong> One Pacific Place, 15th Floor, Jalan
            Jendral Sudirman, Senayan, Jakarta
          </div>
          <div><strong>Phone:</strong> +62 811-1574-110</div>
        </div>
      </div>
      <div
        class="hidden md:flex p-8 lg:p-16 flex-col gap-4 justify-between text-sm lg:text-base bg-bgfooter"
      >
        <img src="../assets/bwx-logo.png" alt="" class="w-1/2 lg:w-1/3" />
        <div><strong>PT. Brainworx Solusi Integrasi</strong></div>
        <div><strong>Email:</strong> info_bsi@brainworx.co.id</div>
        <div>
          <strong>Address:</strong> One Pacific Place, 15th Floor, Jalan Jendral
          Sudirman, Senayan, Jakarta
        </div>
        <div class="mb-4"><strong>Phone:</strong> +62 811-1574-110</div>
        <div class="flex gap-4 text-lg lg:text-xl">
          <a
            href="https://www.facebook.com/brainworxindonesia/"
            target="_blank"
          >
            <Icon icon="akar-icons:facebook-fill" />
          </a>
          <a
            href="https://www.linkedin.com/company/brainworx-solusi-integrasi"
            target="_blank"
          >
            <Icon icon="akar-icons:linkedin-fill" />
          </a>
          <a
            href="https://www.instagram.com/brainworxindonesia/?hl=id"
            target="_blank"
          >
            <Icon icon="akar-icons:instagram-fill" />
          </a>
        </div>
      </div>
      <div class="p-4 md:p-8 lg:p-16">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.2999771413793!2d106.80809236473945!3d-6.224120545494454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f1a29bc71929%3A0xa44ceca0c7cd9204!2sOne%20Pacific%20Place!5e0!3m2!1sid!2sid!4v1665730079897!5m2!1sid!2sid"
          class="w-full lg:w-3/4 h-full mx-auto"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
    <div class="p-4 lg:p-8 bg-bgfooter2 text-xs lg:text-base text-center">
      ©2022 PT. Brainworx Solusi Integrasi
    </div>
  </footer>
</template>

<script>
import { Icon } from "@iconify/vue";
export default {
  name: "FooterPage",
  components: {
    Icon,
  },
};
</script>
