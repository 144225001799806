<template>
  <div
    id="app"
    class="bg-bgutama relative min-h-screen font-fontutama text-white flex flex-col"
  >
    <navbar2
      class="fixed top-0 z-40"
      v-if="
        $route.name == 'articlesDetail' || $route.name == 'leadershipDetail'
      "
    />
    <navbar class="fixed top-0 z-40" v-else />
    <router-view v-if="$route.name != 'home'" v-slot="{ Component, route }">
      <transition
        enter-active-class="animate__animated animate__fadeIn animate__slow"
        mode="in-out"
      >
        <div :key="route.name" class="flex-grow">
          <component :is="Component"></component>
        </div>
      </transition>
    </router-view>
    <router-view v-if="$route.name == 'home'"></router-view>
    <footerpage v-if="$route.name != 'getInTouch'" />
  </div>
  <button
    class="toTopPage hidden fixed bottom-32 right-2 cursor-pointer z-50"
    @click="topPage()"
    id="topBtn"
  >
    <Icon
      icon="akar-icons:arrow-up"
      class="text-black bg-white md:border-2 rounded lg:rounded-xl text-xl lg:text-4xl p-1"
    />
  </button>
</template>

<style>
html {
  scroll-behavior: smooth;
}
body {
  overflow-y: scroll;
}
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: #1e1e1e;
}
::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 15px;
}
::-webkit-scrollbar-thumb:hover {
  background: #141414;
}
</style>

<script>
// import { Head } from "@vueuse/head";
import navbar from "./components/navBar.vue";
import navbar2 from "./components/navBar2.vue";
import footerpage from "./components/footerPage.vue";
import { Icon } from "@iconify/vue";
export default {
  name: "App",
  components: {
    navbar,
    navbar2,
    Icon,
    footerpage,
  },
  data() {
    return {
      isBussy: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.isBussy = false;
    }, 2000);
    var mybutton = document.getElementById("topBtn");
    window.onscroll = function () {
      scrollFunction();
    };
    function scrollFunction() {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        mybutton.style.display = "block";
      } else {
        mybutton.style.display = "none";
      }
    }
    // window.onscroll = function () {
    //   const difference =
    //     document.documentElement.scrollHeight - window.innerHeight;
    //   const scrollposition = document.documentElement.scrollTop;
    //   if (difference - scrollposition <= 2) {
    //     mybutton.style.display = "block";
    //   } else {
    //     mybutton.style.display = "none";
    //   }
    // };
  },
  methods: {
    goto(id) {
      const textElement = document.getElementById(id);
      textElement.scrollIntoView({ behavior: "smooth" });
    },
    topPage() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
