import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./styles/app.css";
import "animate.css";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();
import "flowbite";
import { createHead } from "@vueuse/head";
import { MotionPlugin } from "@vueuse/motion";
import vWow from "v-wow";

createApp(App)
  .use(store)
  .use(router)
  .use(createHead())
  .use(MotionPlugin)
  .use(vWow)
  .mount("#app");
