<template>
  <div
    id="ourFriends"
    class="py-20 md:py-32 relative flex flex-col gap-4 md:gap-0 md:grid grid-cols-5 px-4 md:px-8 lg:px-16 h-fit items-center bg-gradient-to-b from-bgketiga via-bgutama to-bgutama z-0"
  >
    <div class="relative md:hidden w-full z-0">
      <img
        src="../assets/ourFriends.png"
        alt="our-success-with"
        class="z-10 col-span-2 w-3/6"
      />
    </div>
    <img
      src="../assets/bgOurSuccess.png"
      class="absolute bottom-0 left-0 z-0 w-full h-fit hidden md:block"
      alt="our-success-background"
    />
    <img
      src="../assets/ourFriends.png"
      alt="our-success-with"
      class="z-10 col-span-2 w-5/6 hidden md:block"
    />
    <div
      class="z-10 col-span-3 grid grid-cols-4 md:grid-cols-none md:grid-rows-3 md:grid-flow-col gap-4 text-center w-full mx-auto md:overflow-x-auto md:scrollbar-hide lg:scrollbar-default lg:pb-4"
    >
      <div
        v-for="items in friends"
        :key="items.id"
        class="border-gray-600 border rounded-xl h-fit overflow-auto scrollbar-hide !aspect-square md:aspect-auto md:h-28 lg:h-40 md:w-44 lg:w-60 flex items-center"
      >
        <img
          :src="items.image_url"
          :alt="items.name"
          class="m-auto p-2 md:p-0 hover:scale-125 h-auto w-auto transition-transform duration-500"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  mounted() {
    axios
      .get("https://cdn.ultrapay.id/brainworx-web/friends.json?v=2")
      .then((response) => {
        this.friends = response.data;
      });
  },
  data() {
    return {
      friends: [],
    };
  },
};
</script>
