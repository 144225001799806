<template>
  <div class="artikel flex flex-col bg-bgutama">
    <div class="relative z-0">
      <div
        class="text-xl md:text-3xl lg:text-7xl w-full font-fontbold ml-auto py-20 md:py-28 lg:py-40 px-4 md:px-16 z-50"
      >
        {{ this.articleDisplay.title }}
      </div>
      <img
        src="../../src/assets/articles-1.png"
        class="h-full w-full absolute top-0 -z-10"
        alt=""
      />
    </div>
    <div class="bg-bgutama p-4 md:p-8 lg:p-16 flex flex-col gap-4">
      <div class="prose prose-pink text-white max-w-2xl tanggalan mx-auto">
        <p>{{ articleDate }}</p>
        <!-- <a href="https://reactgo.com/vue-render-html-string/">tests</a> -->
      </div>
      <div
        class="isi prose prose-pink text-justify text-white max-w-2xl mx-auto"
        v-html="this.articleDisplay.desc"
      ></div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      article: "",
      articles: [],
      articleDisplay: [],
      articleDate: "Jakarta, Indonesia - October  6th, 2022 :",
      websiteHTMLNo: '<a v-bind:href="https://google.com/">Google</a>', // <-- won't work
      websiteHTMLYes: '<a href="https://google.com/">Google</a>', // <-- do this instead
    };
  },
  mounted() {
    this.article = this.$router.currentRoute.value.params.id;
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
    axios
      .get("https://cdn.ultrapay.id/brainworx-web/leadership.json")
      .then((response) => {
        this.articles = response.data;
        this.totalarticles = this.articles.length;
        this.articleDisplay = this.articles[this.article];
        // console.log(this.articleDisplay);
      });
  },
};
</script>
