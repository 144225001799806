<template>
  <div
    id="services"
    refs="services"
    class="grid grid-cols-4 pt-20 pb-8 md:pb-16 md:pt-32 md:grid-cols-5 gap-4 md:gap-16 pr-4 md:pr-8 lg:pr-16"
  >
    <img
      src="../assets/ourService.png"
      alt="our-service"
      class="col-span-2 w-full z-10"
    />
    <div class="col-span-2 md:col-span-3 my-auto z-10">
      <div class="text-xs md:text-sm lg:text-2xl font-fontutama">
        We strive to provides reliable solutions for your business growth and
        maximizing efficiencies to reduce information technology complexity.
      </div>
      <div
        class="grid overflow-scroll scrollbar-hide text-xxs md:text-xs lg:text-2xl grid-rows-3 mt-4 md:mt-8 gap-y-4 md:gap-y-8"
      >
        <div class="flex gap-4 md:gap-x-8 whitespace-nowrap">
          <div class="rounded-lg p-1 px-2 border-2 border-white">
            Data Operations
          </div>
          <div class="rounded-lg p-1 px-2 border-2 border-white">
            Data Management
          </div>
          <div class="rounded-lg p-1 px-2 border-2 border-white">
            Data Engineering
          </div>
        </div>
        <div class="flex gap-4 md:gap-x-8 whitespace-nowrap">
          <div class="rounded-lg p-1 px-2 border-2 border-white">
            Data Analytics
          </div>
          <div class="rounded-lg p-1 px-2 border-2 border-white">
            Data Streaming
          </div>
          <div class="rounded-lg p-1 px-2 border-2 border-white">
            Data Integration
          </div>
        </div>
        <div class="flex gap-x-4 md:gap-x-8 whitespace-nowrap">
          <div class="rounded-lg p-1 px-2 border-2 border-white">Big Data</div>
          <div class="rounded-lg p-1 px-2 border-2 border-white">
            Data Migration
          </div>
          <div class="rounded-lg p-1 px-2 border-2 border-white">
            Data Pipeline Development
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="px-4 md:px-8 lg:px-16 pb-4 md:pb-8 lg:pb-16">
    <div class="text-xs md:text-sm lg:text-2xl font-fontutama">
      We mainly provides digital services centered around Data services &
      monetization such as:
    </div>
    <ol class="text-xs md:text-sm lg:text-2xl font-fontutama">
      <li class="list-inside list-disc">Mobile kiosk & Payment Services</li>
      <li class="list-inside list-disc">
        Telecom messaging & verification Software services
      </li>
      <li class="list-inside list-disc">Cloud Based Core-Banking services</li>
      <li class="list-inside list-disc">Alternative Score services</li>
    </ol>
  </div>
</template>
