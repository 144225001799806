<template>
  <nav
    :class="{ scrolled: !view.atTopOfPage }"
    class="w-full p-2 md:p-8 lg:p-16 pt-4 lg:pt-8 grid grid-cols-2 md:grid-cols-3 bg-transparent items-center text-white z-50"
  >
    <img
      @click="top()"
      src="../assets/bwx-logo.png"
      class="w-9/12 md:w-4/12 lg:w-5/12 cursor-pointer"
      alt="Brainworx-Company-Logo"
    />
    <button
      type="button"
      @click="toggleMenu"
      class="navbar-burger inline-flex items-center p-2 ml-auto text-sm text-white rounded-lg md:hidden"
      aria-expanded="false"
    >
      <span class="sr-only">Open main menu</span>
      <svg
        class="w-6 h-6"
        aria-hidden="true"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </button>
    <div
      class="col-span-2 Navigation hidden md:flex gap-3 lg:gap-6 xl:gap-4 ml-auto font-semibold text-xxs lg:text-base"
    >
      <a
        class="cursor-pointer border-b-2 border-transparent lg:hover:border-white"
        href="/"
        >Home</a
      >
      <a
        class="cursor-pointer border-b-2 border-transparent lg:hover:border-white"
        href="/products"
        >Products and Services</a
      >
      <a
        class="cursor-pointer border-b-2 border-transparent lg:hover:border-white"
        href="/oursuccess"
        >Our Success</a
      >
      <a
        class="cursor-pointer border-b-2 border-transparent lg:hover:border-white"
        href="/article"
        >Articles & Thought Leadership</a
      >
      <a
        class="cursor-pointer border-b-2 border-transparent lg:hover:border-white"
        href="/visionmission"
        >Vision & Mission</a
      >
      <a class="cursor-pointer hover:border-b border-white" href="/contact"
        >Contact Us</a
      >
    </div>
    <div class="navbar-menu relative z-50 hidden">
      <div
        class="navbar-backdrop fixed inset-0 bg-gray-800 opacity-75"
        @click="closeMenu"
      ></div>
      <nav
        class="fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-2 bg-bgutama overflow-y-auto"
      >
        <div class="flex items-center mb-8 p-2">
          <a class="mr-auto" href="/">
            <img src="../assets/bwx-logo.png" class="w-1/2" alt="" />
          </a>
          <button class="navbar-close" @click="closeMenu">
            <svg
              class="h-6 w-6 text-white active:text-black"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>
        <div>
          <ul class="">
            <li class="mb-2">
              <a
                class="p-2 text-sm mb-2 text-white flex items-center justify-between"
                href="/"
                >Home<Icon icon="akar-icons:chevron-right"
              /></a>
            </li>
            <li class="mb-2">
              <a
                class="p-2 text-sm mb-2 text-white flex items-center justify-between"
                href="/products"
                >Products and Services <Icon icon="akar-icons:chevron-right"
              /></a>
            </li>
            <li class="mb-2">
              <a
                class="p-2 text-sm mb-2 text-white flex items-center justify-between"
                href="/oursuccess"
                >Our Success<Icon icon="akar-icons:chevron-right"
              /></a>
            </li>
            <li class="mb-2">
              <a
                class="p-2 text-sm text-white flex items-center justify-between"
                href="/article"
                >Articles and Thought Leadership<Icon
                  icon="akar-icons:chevron-right"
              /></a>
            </li>
            <li class="mb-2">
              <a
                class="p-2 text-sm text-white flex items-center justify-between"
                href="/visionmission"
                >Vision & Mission <Icon icon="akar-icons:chevron-right"
              /></a>
            </li>
            <li class="mb-2">
              <a
                class="p-2 text-sm text-white flex items-center justify-between"
                href="/contact"
                >Contact Us <Icon icon="akar-icons:chevron-right"
              /></a>
            </li>
          </ul>
        </div>
        <div class="mt-auto">
          <p class="my-4 text-xs text-center text-white">
            <span>©2022 PT. Brainworx Solusi Integrasi</span>
          </p>
        </div>
      </nav>
    </div>
  </nav>
</template>
<style lang="scss">
nav.scrolled {
  background-color: #1e1e1e;
  transition: background-color 1s ease-in-out, padding-top 0.5s ease-in-out;
  padding-top: 16px;
  padding-bottom: 16px;
  // transition: padding-top 0.5s ease-in-out;
}
</style>

<script>
import { Icon } from "@iconify/vue";
export default {
  name: "NavBar",
  components: {
    Icon,
  },
  data() {
    return {
      view: {
        atTopOfPage: true,
      },
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    toggleMenu() {
      document.querySelector(".navbar-menu").classList.toggle("hidden");
    },
    closeMenu() {
      document.querySelector(".navbar-menu").classList.add("hidden");
    },
    goto(id) {
      document.querySelector(".navbar-menu").classList.add("hidden");
      const element = document.getElementById(id);
      const elementRect = element.getBoundingClientRect();
      const absoluteElementTop = elementRect.top + window.pageYOffset;
      const middle = absoluteElementTop - 55;
      window.scrollTo(0, middle);
    },
    top() {
      if (this.$router.currentRoute.value.path === "/") {
        window.scrollTo(0, 0);
      } else {
        this.$router.push("/");
      }
    },
    goBottom() {
      document.querySelector(".navbar-menu").classList.add("hidden");
      window.scrollTo(0, document.body.scrollHeight);
    },
    handleScroll() {
      // when the user scrolls, check the pageYOffset
      if (window.pageYOffset > 0) {
        // user is scrolled
        if (this.view.atTopOfPage) this.view.atTopOfPage = false;
      } else {
        // user is at top of page
        if (!this.view.atTopOfPage) this.view.atTopOfPage = true;
      }
    },
  },
};
</script>
