<template>
  <div
    class="artikel flex flex-col bg-bgutama lg:gap-8 gap-4 pb-4 md:pb-8 lg:pb-16"
  >
    <div class="relative z-0">
      <div
        class="text-xl md:text-3xl lg:text-7xl w-full font-fontbold ml-auto py-20 md:py-28 lg:py-40 px-4 md:px-16 z-50"
      >
        Articles
      </div>
      <img
        src="../../src/assets/bg-article.png"
        class="h-full w-full absolute top-0 -z-10"
        alt=""
      />
    </div>
    <div class="p-4 md:p-8 lg:p-16 flex flex-col gap-4 md:gap-8 lg:gap-16">
      <div
        v-for="item in articles"
        :key="item.id"
        class="grid grid-cols-3 gap-2 md:gap-4 lg:gap-8"
      >
        <img src="../assets/articles-1.png" class="rounded-xl" alt="" />
        <div
          class="col-span-2 font-fontthin text-xs md:text-xs lg:text-5xl font-bold my-auto flex flex-col gap-2 md:gap-4 lg:gap-8"
        >
          {{ item.title }}
          <a
            :href="/article/ + (item.id - 1)"
            class="flex border px-2 py-1 hover:text-bgutama hover:bg-white hover:border-bgutama rounded-3xl gap-2 items-center w-fit text-xs md:text-base"
          >
            See More<Icon icon="akar-icons:arrow-right" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Icon } from "@iconify/vue";
export default {
  components: {
    Icon,
  },
  data() {
    return {
      articles: [],
    };
  },
  mounted() {
    // this.$router.getRoutes();
    // console.log(this.$router.currentRoute.value.path);
    // console.log(this.$router.beforeEach.name);
    window.scrollTo(0, 0);
    // console.log(this.$router.currentRoute.value.params);
    axios
      .get("https://cdn.ultrapay.id/brainworx-web/articles.json")
      .then((response) => {
        this.articles = response.data;
        this.totalarticles = this.articles.length;
        // console.log(this.articles);
      });
  },
};
</script>
