<template>
  <div
    id="main"
    class="animate__animated animate__fadeIn animate__slower pl-4 md:pl-8 lg:pl-16 h-fit md:min-h-screen grid grid-cols-5 relative z-0 bg-bgketiga"
  >
    <img
      src="../assets/bgShadow2.png"
      alt="background-shadow"
      class="absolute w-full md:h-28 lg:h-fit -z-10 bottom-0"
    />
    <img
      :src="imagez"
      alt="background"
      id="imagezutama"
      class="absolute top-0 left-0 h-full w-full -z-20"
    />
    <div class="my-24 md:my-auto col-span-5 md:col-span-2">
      <h1 class="text-xl md:text-2xl lg:text-5xl font-bold">
        Better Solutions For
      </h1>
      <h1 class="text-xl md:text-2xl lg:text-5xl font-bold">Your Business</h1>
      <a
        href="/contact"
        class="w-fit flex mt-4 gap-x-2 text-xxs lg:text-base items-center font-bold p-1 lg:p-2 px-2 lg:px-3 bg-white text-bgutama md:hover:bg-gradient-to-b from-getinTop to-getinBot md:hover:text-white rounded-3xl transition duration-500 ease-in-out"
      >
        Get In Touch
        <Icon icon="fluent:send-20-filled" />
      </a>
    </div>
  </div>
  <div
    id="articles"
    class="p-4 md:p-8 z-10 lg:p-16 text-xxs md:text-sm lg:text-base text-justify bg-gradient-to-b from-bgketiga to-bgutama"
  >
    <div class="justify-between hidden md:flex">
      <h1 class="font-fontbold text-xl md:text-4xl lg:text-6xl mb-4 md:mb-8">
        Articles
      </h1>
      <a href="/article" class="flex mt-auto mb-4 md:mb-8">View All</a>
    </div>
    <div class="flex justify-between md:hidden" @click="toArticle">
      <h1 class="font-fontbold text-xl md:text-4xl lg:text-6xl mb-4 md:mb-8">
        Articles
      </h1>
      <a href="/article" class="flex mt-auto mb-4 md:mb-8">View All</a>
    </div>
    <div
      class="grid grid-rows-1 grid-flow-col md:grid-rows-none md:grid-flow-row md:grid-cols-3 gap-4 md:gap-8 !overflow-x-scroll shrink-0 pb-2 md:pb-0"
      style="webkit-overflow-scrolling: touch"
    >
      <div
        class="w-48 md:w-auto flex flex-col gap-2 md:gap-4 justify-between"
        v-for="item in articles3"
        :key="item.id"
      >
        <img
          src="../assets/articles-1.png"
          class="rounded-xl"
          alt="articles-1"
        />
        <div class="">
          {{ item.title }}
        </div>
        <a
          :href="/article/ + (item.id - 1)"
          class="flex border px-2 py-1 hover:text-bgutama hover:bg-white hover:border-bgutama rounded-3xl gap-2 items-center w-fit mt-auto"
        >
          See More<Icon icon="akar-icons:arrow-right" />
        </a>
      </div>
      <!-- <div class="w-48 md:w-auto flex flex-col gap-2 md:gap-4">
        <img
          src="../assets/articles-2.png"
          class="m-auto rounded-xl"
          alt="articles-2"
        />
        <div>
          Machine Learning: A Significant Player for Big Data in Industrial
          Revolution 4.0
        </div>
        <a
          :href="/article/ + 1"
          class="flex border px-2 py-1 hover:text-bgutama hover:bg-white hover:border-bgutama rounded-3xl gap-2 items-center w-fit"
        >
          See More<Icon icon="akar-icons:arrow-right" />
        </a>
      </div>
      <div class="w-48 md:w-auto flex flex-col gap-2 md:gap-4">
        <img
          src="../assets/articles-3.png"
          class="m-auto rounded-xl"
          alt="articles-3"
        />
        <div>
          Machine Learning: A Significant Player for Big Data in Industrial
          Revolution 4.0
        </div>
        <a
          :href="/article/ + 2"
          class="flex border px-2 py-1 hover:text-bgutama hover:bg-white hover:border-bgutama rounded-3xl gap-2 items-center w-fit"
        >
          See More<Icon icon="akar-icons:arrow-right" />
        </a>
      </div> -->
    </div>
  </div>
  <div
    id="totLeadership"
    class="p-4 md:p-8 lg:p-16 h-fit w-full bg-thought-bg bg-cover relative z-0 grid grid-cols-4 gap-4 md:gap-8 text-xxs md:text-sm lg:text-base"
  >
    <a :href="/leadership/ + 0" class="flex flex-col col-start-2">
      <img
        :src="leadership1.image_url"
        class="w-full h-fit mb-1 md:mb-2"
        alt="thought-leadership"
      />
      <div
        class="text-justify"
        style="
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        "
      >
        {{ leadership1.title }}
      </div>
    </a>
    <a :href="/leadership/ + 1" class="flex flex-col">
      <img
        :src="leadership2.image_url"
        class="mb-1 md:mb-2 w-5/6 ml-auto"
        alt="thought-leadership-1"
      />
      <div
        class="text-justify"
        style="
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        "
      >
        {{ leadership2.title }}
      </div>
    </a>
    <a :href="/leadership/ + 2" class="flex flex-col">
      <img
        :src="leadership3.image_url"
        class="mb-1 md:mb-2 w-5/6 ml-auto"
        alt="thought-leadership-2"
      />
      <div
        class="text-justify"
        style="
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        "
      >
        {{ leadership3.title }}
      </div>
    </a>
    <img
      src="../assets/tot-leader.png"
      class="absolute top-8 md:top-16 lg:top-32 left-4 md:left-8 lg:left-16 z-20 w-2/6"
      alt="thought-leadership-3"
    />
    <!-- <div
      class="absolute text-xxs md:text-sm lg:text-base bottom-2 md:bottom-4 lg:bottom-8 right-4 md:right-8 lg:right-16 flex gap-2 md:gap-4"
    >
      <button
        class="p-0 md:p-1 lg:p-2 border border-white md:rounded rounded-sm"
      >
        <Icon icon="akar-icons:arrow-left" />
      </button>
      <button
        class="p-0 md:p-1 lg:p-2 border border-white md:rounded rounded-sm"
      >
        <Icon icon="akar-icons:arrow-right" />
      </button>
    </div> -->
  </div>
</template>

<style>
::-webkit-scrollbar {
  height: 4px;
}
::-webkit-scrollbar-track {
  background: #1e1e1e;
  border-radius: 15px;
}
::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 15px;
}
::-webkit-scrollbar-thumb:hover {
  background: #1e1e1e;
}
</style>

<script>
import { Icon } from "@iconify/vue";
import axios from "axios";
export default {
  name: "HomeView",
  components: {
    Icon,
  },
  methods: {
    looping() {
      const loopFromOnetoFour = [1, 2, 3, 4];
      loopFromOnetoFour.forEach((item) => {
        setTimeout(() => {
          this.imagez = `https://cdn.ultrapay.id/brainworx-web/background/imgutama${item}.png`;
        }, 5000 * item);
      });
      clearTimeout();
    },
    toArticle() {
      window.location.href = "/article";
    },
  },
  mounted() {
    setInterval(() => {
      this.looping();
    }, 20000);
    axios
      .get("https://cdn.ultrapay.id/brainworx-web/articles.json")
      .then((response) => {
        this.articles = response.data;
        // console.log(this.articles);
        this.articles3 = this.articles.slice(0, 3);
        // console.log(this.articles[0]);
        this.articleslength = this.articles.length;
        this.totalpage = Math.ceil(this.articleslength / 3);
      });
    axios
      .get("https://cdn.ultrapay.id/brainworx-web/leadership.json")
      .then((response) => {
        this.leadership = response.data;
        this.leadershipLength = this.leadership.length;
        this.totalpageleadership = Math.ceil(this.leadershipLength / 3);
        this.leadership1 = this.leadership[0];
        this.leadership2 = this.leadership[1];
        this.leadership3 = this.leadership[2];
        // console.log(this.leadership1);
      });
  },
  data() {
    return {
      techPartner: [],
      friends: [],
      strengths: [],
      articles: [],
      leadership1: [],
      leadership2: [],
      leadership3: [],
      articles3: [],
      itemID: 0,
      imagez: "https://cdn.ultrapay.id/brainworx-web/background/imgutama1.png",
    };
  },
};
</script>
