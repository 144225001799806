<template>
  <div
    id="whoAreWe"
    class="p-4 md:px-8 lg:px-16 py-20 md:py-32 flex flex-col gap-y-4"
  >
    <img
      src="../assets/whoAreWe.png"
      class="w-4/12 md:w-3/12"
      alt="who-are-we"
    />
    <div class="text-xs text-justify lg:text-xl font-fontthin font-bold">
      Brainworx System is a more than 7 years system integrator company with
      focus in Big Data, Security application, Authentication & Verification and
      Data operation manage service. Brainworx system is part of Brainworx
      Ventures, a techno venture company focusing on bringing digital to improve
      social interaction & lifestyle by creating ideas and dreams into a
      reality.
    </div>
    <img src="../assets/ourVision.png" class="w-3/12 md:w-2/12" alt="" />
    <div class="text-xs text-justify lg:text-xl font-fontthin font-bold pb-2">
      Become world class system integrator focusing on Big Data & IT manage
      services to bring the best engineering talent out of Indonesia to the
      world.
    </div>
  </div>
</template>
