<template>
  <div class="mih-h-screen">
    <img src="../assets/Wallpaper.png" class="w-screen pt-24" alt="" />
    <div
      class="grid grid-rows-2 md:grid-rows-none grid-cols-none md:grid-cols-2 gap-8 md:gap-0 p-4 md:p-8 lg:p-16 bg-bgfooter"
    >
      <div class="pr-0 md:pr-16">
        <h1 class="font-fontbold text-xl md:text-3xl lg:text-5xl mb-8 md:mb-16">
          Get In Touch.
        </h1>
        <!-- <form
          action="mailto:aqil.falah2001@gmail.com"
          id="form"
          method="post"
          enctype="plain/text"
          class="flex flex-col gap-8"
        > -->
        <div class="flex flex-col gap-8">
          <input
            type="text"
            v-model="name"
            placeholder="Name..."
            class="bg-getinTextBg text-getinText border-getinText w-full"
          />
          <input
            type="text"
            v-model="phone"
            placeholder="Phone Number..."
            class="bg-getinTextBg text-getinText border-getinText w-full"
          />
          <input
            type="text"
            v-model="email"
            placeholder="Email..."
            class="bg-getinTextBg text-getinText border-getinText w-full"
          />
          <input
            v-model="subject"
            type="text"
            placeholder="Subject..."
            class="bg-getinTextBg text-getinText border-getinText w-full"
          />
          <textarea
            name="message"
            role="text-input"
            v-model="message"
            placeholder="Your Message..."
            class="bg-getinTextBg text-getinText border-getinText w-full h-64 flex flex-grow"
          />
          <!-- <input
          class="p-2 mt-1 flex flex-grow border-4 border-borderdark dark:border-borderdark bg-bgdark dark:bg-textboxdark text-white rounded-lg text-justify placeholder-white"
            type="text"
            placeholder="Your Message..."
            class="bg-getinTextBg text-getinText border-getinText w-full h-64"
          /> -->
          <button
            @click="onSubmit()"
            class="border border-white w-fit py-1 px-2 rounded-full mx-auto font-fontbold cursor-pointer hover:bg-white hover:text-black transition duration-300"
          >
            Send Email
          </button>
        </div>
      </div>
      <div class="flex flex-col">
        <h1 class="font-fontbold text-xl md:text-3xl lg:text-5xl mb-8 md:mb-16">
          Contact Info.
        </h1>
        <div class="flex flex-col flex-grow h-max gap-4">
          <div class="font-fontthin text-lg md:text-2xl uppercase text-start">
            lets talk about your project.
          </div>
          <div class="text-base md:text-lg text-getinText2">
            <strong>Email:</strong> info.bsi@brainworx.co.id
          </div>
          <div class="text-base md:text-lg text-getinText2">
            <strong>Phone:</strong> +62 811-1574-110
          </div>
          <div class="font-fontthin text-lg md:text-2xl uppercase text-start">
            visit us
          </div>
          <div class="text-base md:text-lg text-getinText2">
            <strong>Address:</strong> One Pacific Place, 15th Floor, Jalan
            Jendral Sudirman, Senayan, Jakarta
          </div>
          <div class="flex flex-grow">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.2999771413793!2d106.80809236473945!3d-6.224120545494454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f1a29bc71929%3A0xa44ceca0c7cd9204!2sOne%20Pacific%20Place!5e0!3m2!1sid!2sid!4v1665730079897!5m2!1sid!2sid"
              class="md:w-3/4 w-full h-full mr-auto"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { VueReCaptcha } from "vue-recaptcha-v3";
// import { createApp } from "vue";
// import App from "./App.vue";
export default {
  name: "GetInTouchView",
  data() {
    return {
      name: "",
      phone: "",
      email: "",
      subject: "",
      message: "",
    };
  },
  mounted() {
    // createApp(App).use(VueReCaptcha, {
    //   siteKey: "6LekD44iAAAAAGcBG2KJljys-qAv56qOeJ0vBvbA",
    // });
  },
  methods: {
    // onSubmit() {
    //   this.$refs.recaptcha.execute();
    //   // document.getElementById("form").submit();
    // },
    // async recaptcha() {
    //   // (optional) Wait until recaptcha has been loaded.
    //   await this.$recaptchaLoaded();
    //   // Execute reCAPTCHA with action "login".
    //   const token = await this.$recaptcha("submit");
    //   console.log(token);
    // },
  },
};
</script>
